import Vue from "vue";
import Vuex from "vuex";
import AuthenticationStore from "@/stores/AuthenticationStore";
import LoggingStore from "@/stores/LoggingStore";
import DataStore from "@/stores/DataStore";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth: AuthenticationStore,
    log: LoggingStore,
    data: DataStore,
  },
});
