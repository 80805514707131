import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/api/common/requestInterceptors";
import { store } from "@/store";

// This imports all the layout components such as <b-container>, <b-row>, <b-col>:
import { LayoutPlugin } from "bootstrap-vue";
Vue.use(LayoutPlugin);

// This imports <b-modal> as well as the v-b-modal directive as a plugin:
import { ModalPlugin } from "bootstrap-vue";
Vue.use(ModalPlugin);

// This imports <b-card> along with all the <b-card-*> sub-components as a plugin:
import { CardPlugin } from "bootstrap-vue";
Vue.use(CardPlugin);

// This imports directive v-b-scrollspy as a plugin:
import { VBScrollspyPlugin } from "bootstrap-vue";
Vue.use(VBScrollspyPlugin);

// This imports the dropdown and table plugins
import {
  SpinnerPlugin,
  FormInputPlugin,
  FormGroupPlugin,
  FormPlugin,
  ButtonPlugin,
  ToastPlugin,
} from "bootstrap-vue";
Vue.use(SpinnerPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormPlugin);
Vue.use(ButtonPlugin);
Vue.use(FormGroupPlugin);
Vue.use(ToastPlugin);

//import { BootstrapVue } from "bootstrap-vue";
// Vue.use(BootstrapVue);

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// custom stylesa
import "@/assets/scss/global.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
